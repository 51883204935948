// LIBRARIES
import '../css/Home.css';
import React, { useLayoutEffect, useState } from 'react';

// COMPONENTS
import Entry from '../components/Entry'

// ASSETS
import wolf from '../assets/wolf-icon.png';
import placeholder from '../blog-bodies/placeholder.pdf'
import assignment1report from '../blog-bodies/assignment1report.pdf'
import assignment1presentation from '../blog-bodies/assignment1presentation.pdf'
import josh from '../assets/josh.jpeg'

const Home = () => {
    
    return(
        <div className="Home">
            <div className="about">
                <h1>GROUP MEMBERS:</h1>
                <p>Huy, Maria, Lily, Nick, Shabicha, Emma</p>
                <h1>ABOUT OUR PROJECT:</h1>
                <p>
                    ScummVM is an open source program that emulates via virtual machine the recreated version of a set of game engines, which allows users to play classic graphic point-and-click or text adventure games, as well as RPGs; given that you have the files to the game. These game engines are rewritten versions of existing game engines, with the ability to load the music, textures, scripts, shaders, levels, etc, from games made using the original game engine. The engine was originally designed to play games from LucasArt’s SCUMM system developed in 1987 to help with development of their new graphic adventure game Maniac Mansion. ScummVM has now expanded and is currently capable of emulating just under 500 games with its many different game engines.
                </p>
            </div>

            {/* <div className="blog">
                <h1>ASSIGNMENT 0:</h1>
                <h3>Lorem ipsum odor amet, consectetuer adipiscing elit. Cubilia dapibus sociosqu massa vel nullam montes orci ex. Pharetra praesent fringilla quis etiam elementum sem lacinia commodo nam. Orci potenti adipiscing nisi class fusce.</h3>
                <a href={placeholder} download={placeholder}>
                        <h2>DOWNLOAD: Placeholder.pdf</h2>
                </a>
                <object className="pdf" data={placeholder} type="application/pdf">
                    <a href={placeholder} download={placeholder}>
                        <p>COULD NOT RENDER THE PDF, CLICK TO VIEW</p>
                    </a>
                    
                </object>
            </div> */}

            <div className="blog">
                <h1>ASSIGNMENT 1:</h1>
                <h3>Conceptual Architecture of ScummVM<br/>Report:</h3>
                <a href={assignment1report} download={assignment1report}>
                        <h2>DOWNLOAD: assignment1report.pdf</h2>
                </a>
                <object className="pdf" data={assignment1report} type="application/pdf">
                    <a href={assignment1report} download={assignment1report}>
                        <p>COULD NOT RENDER THE PDF, CLICK TO VIEW</p>
                    </a>
                    
                </object>
                <h3>Presentation:</h3>
                <a href={assignment1presentation} download={assignment1presentation}>
                        <h2>DOWNLOAD: assignment1presentation.pdf</h2>
                </a>
                <object className="pdf" data={assignment1presentation} type="application/pdf">
                    <a href={assignment1presentation} download={assignment1presentation}>
                        <p>COULD NOT RENDER THE PDF, CLICK TO VIEW</p>
                    </a>
                    
                </object>
            </div>
            

            <img className="josh" src={josh} alt="hi josh"/>
        </div>
    )
}

export default Home;