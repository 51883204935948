// LIBRARIES
import React, { useLayoutEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
} from 'react-router-dom';

// ASSETS AND RESOURCES
import logo from './wolf-face.png';
import './css/App.css';

// PAGES
import Home from './pages/Home';

// COMPONENTS
import Header from './components/Header';

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function App() {
  const [w, h] = useWindowSize();
  const [open, setOpen] = useState(0);

  return (
    <div className="App">
      {Header()}

      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />

          {/* <Route path="*" element={<ErrorPage />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
