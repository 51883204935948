import '../css/Header.css';
import React, { useLayoutEffect, useState } from 'react';
import wolf from '../assets/wolf-icon.png';

const Header = () => {
    return (
        <div className="Header">
            <img src={wolf}/>
            <a href="/home">CISC322/326 - GROUP 23 - THE SOFTWEREWOLVES</a>
        </div>
    );
}

export default Header;