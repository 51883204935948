import '../css/Entry.css';
import React, { useEffect, useState } from 'react';

const Entry = (props) => {

    const [body, setBody] = useState('');

    useEffect(() => {
    // Fetch the file from the public directory
    fetch('/blog-bodies/'+props.body+'.pdf')
      .then((response) => response.text())
      .then((text) => setBody(text));
    }, []);

    return (
        <div className="Entry">
            <div className="title">{props.index}. {props.title}</div>
            <div className="name">{props.name}</div>
            <div className="date">{props.date}</div>
            <object data={body} type="application/pdf" width="100%" height="100%">
                <p>Alternative text - include a link <a href="http://africau.edu/images/default/sample.pdf">to the PDF!</a></p>
            </object>
        </div>
    )
}

export default Entry;